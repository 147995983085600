import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { programDefaultPtaasDescription, Languages } from '../constants/Data';
import { programDefaultPtaasDescriptionUS } from '../constants/DataUS';

import { MarkOptions } from '../utils/MarkdownHelper';
import '../themes/program-form.less';
import '../themes/default.less';

import axiosInstance from '../axiosInstance';
import { handleAxiosError } from '../utils/Http';

import DynamicTargetList from '../components/DynamicTargetList';

import Icon, {
  CameraOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Tabs,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Select,
  Upload,
  DatePicker,
} from 'antd';
import moment from 'moment';
import * as _ from 'lodash';
import Markdown from 'react-remarkable';
import { withTranslation } from 'react-i18next';
import { ProgramTypes } from '../constants/Data';
import { openNotification } from '../utils/General';
import ManyFilesField from '../components/fields/ManyFilesField';

// Variables
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const RangePicker = DatePicker.RangePicker;
const { TextArea } = Input;

class ProgramPtaasForm extends Component {
  constructor(props) {
    super(props);
    const language = localStorage.getItem('selectedLanguage');
    this.state = {
      initialValues: props.initialValues || {
        program_types: [],
        description:
          language === 'en'
            ? programDefaultPtaasDescriptionUS
            : programDefaultPtaasDescription,
        pentest_as_a_service: {},
      },
      saving: false,
      draftMode: props.initialValues ? props.initialValues.is_draft : true,
      programAvatarUpload: null,
      programAvatarUploadContent: null,
      avatar64: null,
      mayCropFile: false,
      uploadingFile: false,
    };

    this.state.initialValues = {
      ...this.state.initialValues,
      program_types: this.state.initialValues.program_types.map(
        (item) => item.code
      ),
      file_ids: props.initialValues
        ? props.initialValues.files.map((f) => f.id)
        : [],
    };
  }

  componentDidMount() {
    const { form } = this.props;
    const { initialValues } = this.state;
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }

  getCurrentDate() {
    return new Date();
  }

  getProgramTypes() {
    const { t } = this.props;
    return ProgramTypes.map((p, index) => {
      return (
        <Option value={p.code} key={p.code}>
          {t(`programs.program-types.${p.code}`)}
        </Option>
      );
    });
  }

  getLanguages() {
    return Languages.map((p) => {
      return (
        <Option value={p.code} key={p.code}>
          {this.props.t(`languages.${p.code}`)}
        </Option>
      );
    });
  }

  // OVERVIEW
  updateAvatar = () => {
    const { t } = this.props;
    const props = {
      customRequest: function () {
        return null;
      },
      accept: 'image/*',
      showUploadList: false,
      beforeUpload: (file) => {
        const isLt60M = file.size / 1024 / 1024 < 60;
        if (!isLt60M) {
          openNotification(
            'warning',
            t('notifications.warning'),
            t('file-less-than-size', { size: '60MB' })
          );
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            this.setState({
              programAvatarUpload: file,
              avatar64: base64String,
              mayCropFile: true,
            });
          };
          reader.onload = function (e) {}.bind(this);
          reader.readAsDataURL(file);
        }
        return isLt60M;
      },
    };

    return (
      <Upload {...props} className="avatar-uploader">
        <Button shape="circle" icon={<CameraOutlined />} />
      </Upload>
    );
  };

  cropImageDone = (croppedFile) => {
    this.setState({ mayCropFile: false, programAvatarUpload: croppedFile });
  };

  getDescription(e) {
    const desc = e.target.value;
    this.setState({ description: desc });
  }

  handleSubmit = () => {
    const { draftMode } = this.state;
    const { t, form } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          values.is_draft = draftMode;

          values.pentest_as_a_service.start_at =
            values.pentest_as_a_service.range[0].format('YYYY-MM-DD');
          values.pentest_as_a_service.end_at =
            values.pentest_as_a_service.range[1].format('YYYY-MM-DD');
          delete values.pentest_as_a_service.range;

          if (this.props.initialValues) {
            // Send PATCH request to update the entity
            this.setState({
              saving: true,
            });
            const response = await axiosInstance.patch(
              `/programs/${this.props.initialValues.id}/`,
              values
            );
            openNotification(
              'success',
              `${t('notifications.programs.create')}`,
              `${t('notifications.programs.create-message')}`
            );

            this.props.history.push(`/dashboard/programs/${response.data.id}`);
          } else {
            // Send POST request to create a new entity
            const response = await axiosInstance.post('/programs/', values);
            openNotification(
              'success',
              `${t('notifications.programs.update')}`,
              `${t('notifications.programs.update-message')}`
            );
            this.props.history.push(`/dashboard/programs/${response.data.id}`);
          }
        } catch (error) {
          handleAxiosError(error);
        } finally {
          this.setState({
            saving: false,
          });
        }
      }
    });
  };

  render() {
    const { t, program } = this.props;
    const { initialValues } = this.state;
    const { getFieldDecorator } = this.props.form;

    let currentDate = this.getCurrentDate();
    const credits =
      this.props.program && this.props.program.pentest_as_a_service
        ? this.props.program.pentest_as_a_service.credits
        : null;

    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.setState(
            { draftMode: e.nativeEvent.submitter.name === 'draft' },
            () => this.handleSubmit(e)
          );
        }}
        id="ProgramForm"
        layout="vertical"
      >
        <Tabs defaultActiveKey={'main'} activeKey={'main'} tabPosition="top">
          <TabPane
            className="tab-pane"
            tab={
              <div>
                <Icon className="tab-item-pane">
                  <span>1</span>
                </Icon>{' '}
                {t('programs.form.tabs.1')}{' '}
              </div>
            }
            key="main"
          >
            <Row>
              <h2>{t('programs.form.scope')}</h2>
            </Row>

            <FormItem label={t('programs.form.fields.name.name')}>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: t('programs.form.fields.name.rules.required'),
                  },
                  {
                    max: 64,
                    message: t('programs.form.fields.name.rules.max'),
                  },
                ],
              })(
                <Input
                  placeholder={t('programs.form.fields.name.placeholder')}
                />
              )}
            </FormItem>

            <Row gutter={16}>
              <Col sm={24}>
                <FormItem label={t('programs.form.fields.program-type.name')}>
                  {getFieldDecorator('program_types', {
                    rules: [
                      {
                        required: true,
                        message: t(
                          'programs.form.fields.program-type.rules.required'
                        ),
                      },
                    ],
                  })(<Select mode="multiple">{this.getProgramTypes()}</Select>)}
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label={t('programs.form.fields.project-id.name')}>
                  {getFieldDecorator('custom_id', {
                    rules: [{ required: false }],
                  })(
                    <Input
                      placeholder={t(
                        'programs.form.fields.project-id.placeholder'
                      )}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={10} offset={2}>
                <FormItem label={t('programs.form.fields.test-type.name')}>
                  {getFieldDecorator('pentest_as_a_service.test_type', {
                    rules: [{ required: true }],
                  })(
                    <Select
                      placeholder={t(
                        'programs.form.fields.test-type.placeholder'
                      )}
                    >
                      <Option value="black-box">Black Box</Option>
                      <Option value="white-box">White Box</Option>
                      <Option value="gray-box">Gray Box</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <FormItem label={t('programs.form.fields.date.name')}>
                  {getFieldDecorator('pentest_as_a_service.range', {
                    rules: [
                      {
                        required: true,
                        message: t(
                          'programs.form.fields.date.rules.required.name'
                        ),
                      },
                    ],
                    initialValue: [
                      moment(
                        initialValues.pentest_as_a_service.start_at
                          ? initialValues.pentest_as_a_service.start_at
                          : currentDate
                      ),
                      moment(
                        initialValues.pentest_as_a_service.end_at
                          ? initialValues.pentest_as_a_service.end_at
                          : currentDate
                      ),
                    ],
                  })(
                    <RangePicker
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                    />
                  )}
                </FormItem>
              </Col>

              <Col span={10} offset={2}>
                <FormItem label={t('programs.form.fields.credits.name')}>
                  {getFieldDecorator('pentest_as_a_service.credits', {
                    rules: [{ required: true }],
                    initialValue: credits,
                  })(
                    <Input
                      placeholder={t(
                        'programs.form.fields.credits.placeholder'
                      )}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <FormItem label={t('programs.form.fields.description.name')}>
              <div className="markdown">
                <div className="markdown-header">
                  <div className="pull-left">
                    <p>{t('programs.features.edit')}</p>
                  </div>
                  <div className="pull-right">
                    <RadioGroup defaultValue="a" className="markdown-buttons">
                      <RadioButton value="a" onClick={this.editMarkdown}>
                        <EditOutlined />
                      </RadioButton>

                      <RadioButton value="b" onClick={this.showPreview}>
                        <EyeOutlined />
                      </RadioButton>
                    </RadioGroup>
                  </div>
                </div>
                {getFieldDecorator('description', {
                  rules: [
                    {
                      required: true,
                      message: t('programs.form.fields.description.name'),
                    },
                  ],
                })(
                  <TextArea
                    placeholder={t('programs.form.fields.description.name')}
                    onChange={(e) => this.getDescription(e)}
                    rows={6}
                  />
                )}
                <div className="tui-editor-contents description-p hidden">
                  <Markdown
                    source={this.state.description}
                    options={MarkOptions}
                  />
                </div>
              </div>
            </FormItem>

            <DynamicTargetList
              label={t('programs.form.fields.target.name')}
              form={this.props.form}
              values={initialValues.targets}
              fieldOptions={{
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please input target or delete this field.',
                  },
                ],
              }}
            />

            <FormItem>
              {getFieldDecorator(`file_ids`)(
                <ManyFilesField
                  filesProp={
                    this.props.initialValues
                      ? this.props.initialValues.files
                      : []
                  }
                  onSuccess={(response) => {
                    openNotification(
                      'success',
                      t('notifications.files.upload'),
                      t('notifications.files.upload-message')
                    );
                  }}
                  onError={(error) => {
                    handleAxiosError(error);
                  }}
                  onRemoveItem={(error) => {}}
                  onFileSizeError={() => {
                    openNotification(
                      'warning',
                      `${t('notifications.warning')}`,
                      `${t('notifications.files.size-check')}`
                    );
                  }}
                />
              )}
            </FormItem>

            <hr />

            <Row>
              <Col sm={10}>
                <FormItem label={t('programs.form.fields.language.name')}>
                  {getFieldDecorator('language', {
                    rules: [
                      {
                        required: false,
                        message: t(
                          'programs.form.fields.language.rules.required'
                        ),
                      },
                    ],
                    initialValue:
                      program && program.language
                        ? `${program.language}`
                        : undefined,
                  })(
                    <Select
                      placeholder={t(
                        'programs.form.fields.language.placeholder'
                      )}
                      className="placeholderNewProgram"
                    >
                      {this.getLanguages()}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>

            {!initialValues || initialValues.is_draft ? (
              <Row gutter={24}>
                <Col xs={12} sm={12} md={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    name="draft"
                    className="btn-draft btn-block"
                  >
                    {t('programs.form.save-draft-button')}
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <Button
                    size="large"
                    className="program-form-btn btn-company block-center"
                    value="2"
                    htmlType="submit"
                    name="final"
                    loading={this.state.loadingButton}
                  >
                    {t('programs.form.save-button')}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Col xs={24} sm={24} md={24}>
                <Button
                  size="large"
                  className="program-form-btn btn-company block-center"
                  value="2"
                  htmlType="submit"
                  name="final"
                  loading={this.state.loadingButton}
                >
                  {this.props.initialValue
                    ? t('programs.form.save-continue-button')
                    : t('programs.form.save-button')}
                </Button>
              </Col>
            )}
          </TabPane>
        </Tabs>
      </Form>
    );
  }
}

const NormalProgramForm = Form.create()(ProgramPtaasForm);
export default withTranslation()(withRouter(NormalProgramForm));
