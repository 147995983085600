import React, { Component } from 'react';
import {
  CloseCircleOutlined,
  FilterOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Input, Row, Select, Button, Checkbox, Card } from 'antd';
import {
  ReportStatusList,
  SeverityList,
  mitigationStatusList,
  retestStatusList,
} from '../constants/Data';
import '../styles/reports.less';
import * as _ from 'lodash';
import '../themes/loading-icon.less';
import '../themes/reports-export.less';
import { withTranslation } from 'react-i18next';

const FormItem = Form.Item;
const Option = Select.Option;

class FilterReportForm extends Component {
  static defaultProps = {
    onFilterChange: () => {},
    handleSubmit: () => {},
    onToggleShowFilter: () => {},
    renderDownload: () => {},
    showFilters: true,
  };

  componentDidMount() {
    this.checkFilterStatus();
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (filters.status && filters.status !== prevProps.filters.status) {
      this.checkFilterStatus();
    }
  }

  checkFilterStatus = () => {
    const { form, filters } = this.props;

    if (filters.status) {
      if (
        filters.status.includes('new') &&
        filters.status.includes('triage') &&
        filters.status.includes('missing-info') &&
        filters.status.includes('quarantine')
      ) {
        form.setFieldsValue({ action: ['reviewed'] });
      } else {
        form.setFieldsValue({ action: [] });
      }
    }
  };

  clearSearchBox = () => {
    this.props.onSearchChange('');
    const {
      form: { setFieldsValue },
    } = this.props;
    setFieldsValue({ search: '' });
  };

  checkParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const programParam = searchParams.get('program') || null;

    return programParam;
  }

  handleActionChange = (value) => {
    if (value.length === 0) {
      this.props.onFilterChange('status', []);
      this.props.form.resetFields(['status']);
    } else {
      this.props.form.resetFields(['status']);
      this.props.onFilterChange('status', [
        'new',
        'triage',
        'missing-info',
        'quarantine',
      ]);
    }
  };

  onDraftChange = (e) => {
    const { checked } = e.target;
    this.props.form.validateFields((err, values) => {
      values['byDraft'] = checked;
      this.callFiltersDispacher(values);
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      targets,
      programs,
      t,
      showFilters,
    } = this.props;

    const suffix = this.props.filters.search ? (
      <CloseCircleOutlined
        style={{ fontSize: 16 }}
        onClick={this.clearSearchBox}
      />
    ) : (
      <SearchOutlined style={{ fontSize: 16 }} />
    );

    const StatusList = Object.keys(ReportStatusList).map((k) => (
      <Option key={k} value={k}>
        {t(`reports.filters.status.${k}`)}
      </Option>
    ));

    const RetestStatusList = retestStatusList.map((status) => {
      return (
        <Option value={`${status}`} key={status}>
          {t(`reports.retest.${status}`)}
        </Option>
      );
    });
    const MitigationStatusList = mitigationStatusList.map((status) => {
      return (
        <Option value={`${status}`} key={status}>
          {t(`reports.mitigation.${status}`)}
        </Option>
      );
    });

    const MyProgramList = programs.map((program, index) => {
      return (
        <Option value={program.id} key={index}>
          {program.name}
        </Option>
      );
    });

    const Severities = Object.keys(SeverityList).map((k) => (
      <Option value={k} key={k}>
        {t(`reports.filters.severity.${k}`)}
      </Option>
    ));

    const TargetsList = targets.map((target, index) => {
      return (
        <Option value={target} key={index}>
          {target}
        </Option>
      );
    });

    return (
      <Card>
        <Form onSubmit={this.props.handleSubmit} id="filter-report-form">
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 10 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              style={{ paddingLeft: '10px' }}
            >
              <FormItem>
                {getFieldDecorator('search', {
                  initialValue: this.props.filters.search,
                })(
                  <Input
                    placeholder={t('reports.filters.search-box')}
                    onChange={(e) => this.props.onSearchChange(e.target.value)}
                    suffix={suffix}
                  />
                )}
              </FormItem>
            </Col>

            <Col
              xs={{ span: 7, offset: 1 }}
              sm={{ span: 3, offset: 1 }}
              md={{ span: 3, offset: 1 }}
              lg={{ span: 2, offset: 1 }}
              style={{ textAlign: 'left' }}
            >
              <Button
                style={{ width: 'auto', height: '37px' }}
                onClick={this.props.onToggleShowFilter}
              >
                <FilterOutlined style={{ padding: '0', fontSize: 18 }} />
              </Button>
            </Col>

            <Col
              xs={{ span: 14, offset: 2 }}
              sm={{ span: 6, offset: 4 }}
              md={{ span: 5, offset: 5 }}
              lg={{ span: 5, offset: 6 }}
            >
              {this.props.companyMode && (
                <div>
                  <FormItem>{this.props.renderDownload()}</FormItem>
                </div>
              )}
            </Col>
          </Row>
          {showFilters === true && (
            <React.Fragment>
              <Row className="mb-3">
                {this.props.companyMode && (
                  <Col xs={24} sm={12} md={6}>
                    <p style={titleStyle}>
                      {t('reports.filters.actions.title')}
                    </p>
                    <FormItem>
                      {getFieldDecorator(
                        'action',
                        {}
                      )(
                        <Select
                          allowClear
                          height={10}
                          mode="multiple"
                          onChange={this.handleActionChange}
                          placeholder={t('reports.filters.actions.desc')}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option key={'reviewed'}>
                            {t('reports.filters.actions.reviewed')}
                          </Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                )}
                <Col xs={24} sm={12} md={6}>
                  <p style={titleStyle}>{t('reports.filters.program.title')}</p>
                  <FormItem>
                    {getFieldDecorator('program', {
                      initialValue: this.props.filters.program,
                    })(
                      <Select
                        allowClear
                        mode="multiple"
                        onChange={(value) =>
                          this.props.onFilterChange('program', value)
                        }
                        placeholder={t('reports.filters.program.desc')}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {MyProgramList}
                      </Select>
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} sm={12} md={6}>
                  <p style={titleStyle}>{t('reports.filters.status.title')}</p>
                  <FormItem>
                    {getFieldDecorator('status', {
                      initialValue: this.props.filters.status,
                    })(
                      <Select
                        allowClear
                        mode="multiple"
                        onChange={(value) =>
                          this.props.onFilterChange('status', value)
                        }
                        placeholder={t('reports.filters.status.desc')}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {StatusList}
                      </Select>
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} sm={12} md={6}>
                  <p style={titleStyle}>
                    {t('reports.filters.severity.title')}
                  </p>
                  <FormItem>
                    {getFieldDecorator('severity', {
                      initialValue: this.props.filters.severity,
                    })(
                      <Select
                        mode="multiple"
                        allowClear
                        onChange={(value) =>
                          this.props.onFilterChange('severity', value)
                        }
                        placeholder={t('reports.filters.severity.desc')}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {Severities}
                      </Select>
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} sm={12} md={6}>
                  <p style={titleStyle}>Target</p>
                  <FormItem>
                    {getFieldDecorator('target', {
                      initialValue: this.props.filters.targets,
                    })(
                      <Select
                        mode="multiple"
                        allowClear
                        onChange={(value) =>
                          this.props.onFilterChange('targets', value)
                        }
                        placeholder={t('reports.filters.target.desc')}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {TargetsList}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                {!this.props.companyMode && (
                  <Col xs={24} sm={12} md={6}>
                    <FormItem
                      className="text-left"
                      style={{ marginTop: '3vh', marginLeft: '1vh' }}
                    >
                      {getFieldDecorator('byDraft')(
                        <Checkbox
                          onChange={(e) =>
                            this.props.onFilterChange(
                              'is_draft',
                              e.target.checked
                            )
                          }
                        >
                          <span style={{ fontWeight: '600', fontSize: '14px' }}>
                            {t('reports.status.draft')}
                          </span>
                        </Checkbox>
                      )}
                    </FormItem>
                  </Col>
                )}
                {this.props.companyMode && (
                  <React.Fragment>
                    <Col xs={24} sm={12} md={6}>
                      <p style={titleStyle}>
                        {t('reports.filters.retest.title')}
                      </p>
                      <FormItem>
                        {getFieldDecorator('retest', {
                          initialValue: this.props.filters.retest_status,
                        })(
                          <Select
                            mode="multiple"
                            allowClear
                            onChange={(value) =>
                              this.props.onFilterChange('retest_status', value)
                            }
                            placeholder={t('reports.filters.status.desc')}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {RetestStatusList}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <p style={titleStyle}>
                        {t('reports.filters.mitigation.title')}
                      </p>
                      <FormItem>
                        {getFieldDecorator('mitigation', {
                          initialValue: this.props.filters.mitigation_status,
                        })(
                          <Select
                            mode="multiple"
                            allowClear
                            onChange={(value) =>
                              this.props.onFilterChange(
                                'mitigation_status',
                                value
                              )
                            }
                            placeholder={t('reports.filters.status.desc')}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {MitigationStatusList}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                  </React.Fragment>
                )}
                <Col xs={24} sm={12} md={6}>
                  <p style={titleStyle}>
                    {t('reports.filters.program_type.title')}
                  </p>
                  <FormItem>
                    {getFieldDecorator('is_ptaas', {
                      initialValue: this.props.filters.is_ptaas,
                    })(
                      <Select
                        placeholder={t('reports.filters.program_type.title')}
                        allowClear
                        onChange={(value) =>
                          this.props.onFilterChange('is_ptaas', value)
                        }
                      >
                        <Option value={true}>PTaaS</Option>
                        <Option value={false}>Bug Bounty</Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Form>
      </Card>
    );
  }
}

const titleStyle = {
  fontWeight: 'bold',
  textAlign: 'left',
  marginLeft: '12px',
};

const WrappedFilterReportForm = Form.create()(FilterReportForm);

export default withTranslation()(WrappedFilterReportForm);
