import React from 'react';
import { Modal, Table, Tag } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const RetestModal = (props) => {
  const { isOpen, onClose, retests } = props;
  const { t } = useTranslation();

  const columns = [
    {
      title: (
        <span style={{ fontWeight: 'bold' }}>
          {t('reports.retest.req-date')}
        </span>
      ),
      dataIndex: 'requested_at',
      align: 'center',
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: (
        <span style={{ fontWeight: 'bold' }}>
          {t('reports.retest.delivery-date')}
        </span>
      ),
      dataIndex: 'finished_at',
      align: 'center',
      render: (text) => text && moment(text).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: (
        <span style={{ fontWeight: 'bold' }}>{t('reports.retest.status')}</span>
      ),
      dataIndex: 'status',
      align: 'center',
      render: (text) => <span> {t(`reports.retest.${text}`)}</span>,
    },
    {
      title: (
        <span style={{ fontWeight: 'bold' }}>
          {t('reports.retest.mitigation')}
        </span>
      ),
      dataIndex: 'mitigation_status',
      align: 'center',
      render: (status) => {
        if (status === null) return;

        let color =
          {
            fixed: 'green',
            'partially-fixed': 'orange',
            'can-not-verify': 'orange',
            'not-fixed': 'red',
          }[status] ?? 'default';

        return <Tag color={color}>{t(`reports.mitigation.${status}`)}</Tag>;
      },
    },
    {
      dataIndex: 'retest_report_url',
      title: (
        <span style={{ fontWeight: 'bold' }}>
          {t('reports.retest.attached')}
        </span>
      ),
      align: 'center',
      render: (url) => {
        if (url !== null) {
          return (
            <a href={url}>
              <FileOutlined />
            </a>
          );
        }
      },
    },
  ];

  return (
    <Modal
      title={t('reports.retest.history')}
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
      width="60vw"
    >
      <p className="mb-3">{t('reports.retest.description')}</p>
      <Table
        columns={columns}
        dataSource={retests}
        pagination={false}
        className="mb-5"
      />
    </Modal>
  );
};

export default RetestModal;
