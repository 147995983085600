import React, { useState } from 'react';

import {
  EditOutlined,
  RightOutlined,
  UserOutlined,
  HistoryOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Popconfirm,
  Modal,
  Spin,
  Tag,
  Image,
  Tooltip,
} from 'antd';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ChangeStatusReport from './ChangeStatusReport';
import Marckdown from 'react-remarkable';
import { MarkOptions } from '../utils/MarkdownHelper';
import { SeverityList } from '../constants/Data';
import CommentList from './CommentList';
import ReportsExport from './ReportsExport';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import ReportStepsDetail from './ReportStepsDetail';

import HistoryList from './HistoryList';
import LoaderComponent from './LoaderComponent';
import RetestModal from './RetestModal';

const ReportDetail = (props) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleMitigate = (e) => {
    const status = e.key;
    props.handleMitigate(status);
  };

  const handleRequestRetest = (e) => {
    const status = e.key === undefined ? 'pending' : e.key;
    props.handleRequestRetest(status);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const getEditButton = () => {
    return (
      <Link
        to={{
          pathname: `/dashboard/reports/${props.report.id}/update`,
          state: {
            report: { ...props.report, targets: props.targets },
            program: props.report.program,
            isEditing: true,
          },
        }}
        style={{ textDecoration: 'none' }}
      >
        <span style={{ width: '100%', fontSize: '18px' }}>
          {t('reports.edit')}
        </span>
      </Link>
    );
  };

  const getDeleteButton = () => {
    return (
      <span
        style={{ width: '100%', fontSize: '18px', marginBottom: '5px' }}
        onClick={confirmReportDeletion}
      >
        {t('reports.remove')}
      </span>
    );
  };

  const confirmReportDeletion = () => {
    Modal.confirm({
      title: t(`reports.dialogs.delete-report.confirm.title`),
      okText: t(`yes`),
      okType: 'danger',
      cancelText: t(`no`),
      onOk() {
        deleteReport();
      },
      onCancel() {},
    });
  };

  const deleteReport = () => {
    props.handleDeleteReport();
  };

  const mitigationMenuItems = [
    { key: 'pending', label: t('reports.mitigation.pending') },
    { key: 'can-not-verify', label: t('reports.mitigation.can-not-verify') },
    { key: 'not-fixed', label: t('reports.mitigation.not-fixed') },
    { key: 'fixed', label: t('reports.mitigation.fixed') },
    { key: 'partially-fixed', label: t('reports.mitigation.partially-fixed') },
  ];

  const retestMenuItems = [
    {
      key: 'ongoing',
      label: t('reports.retest.ongoing'),
    },
    {
      key: 'completed',
      label: t('reports.retest.completed'),
    },
  ];

  const getOptionsMenu = () => {
    const items = [];
    if (props.canEdit) {
      items.push({
        key: '1',
        label: getEditButton(),
      });
    }
    if (props.canDelete) {
      items.push({
        key: '2',
        label: getDeleteButton(),
      });
    }
    if (props.canExport) {
      items.push({
        key: '3',
        label: <ReportsExport report={props.report} />,
      });
    }
    return items;
  };

  const renderMitigationStatus = (mitigationStatus) => {
    let color;

    switch (mitigationStatus) {
      case 'fixed':
        color = 'green';
        break;
      case 'partially-fixed':
      case 'can-not-verify':
        color = 'orange';
        break;
      case 'not-fixed':
        color = 'red';
        break;
      default:
        color = 'default';
    }

    return (
      <Tag color={color}>{t(`reports.mitigation.${mitigationStatus}`)}</Tag>
    );
  };

  const severityAttr = SeverityList[props.report.severity];

  const addedAt = props.report.added_at
    ? moment(props.report.added_at).format('DD/MM/YYYY HH:mm')
    : '-';

  const mitigationStatus =
    props.report.mitigation_status !== null
      ? props.report.mitigation_status
      : 'pending';

  if (props.loading) return <LoaderComponent label={false} />;

  const itemsPerPage = 10;

  const filteredCompletedRetests = props.retests
    .filter((item) => item.status === 'completed')
    .sort((a, b) => new Date(b.requested_at) - new Date(a.requested_at));

  const latestCompletedRetest =
    filteredCompletedRetests.length > 0 ? filteredCompletedRetests[0] : null;

  return (
    <div id="report-detail">
      <Card hoverable={false}>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 8 }}>
            <p className="report-name ">
              {props.report.name ? props.report.name : '-'}
            </p>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 2, offset: 9 }}
            lg={{ span: 1, offset: 10 }}
          >
            <ChangeStatusReport
              report={props.report}
              onChangeStatus={props.handleChangeStatus}
              disabled={!props.canChangeStatus}
            />
          </Col>
          <Col
            xs={{ span: 3, offset: 9 }}
            sm={{ span: 1, offset: 4 }}
            lg={{ span: 1, offset: 4 }}
          >
            <Dropdown
              disabled={getOptionsMenu().length === 0}
              menu={{ items: getOptionsMenu() }}
              trigger={['click']}
            >
              <Button style={styleButtonDrop}>
                <Image
                  style={{ width: '5px', height: '20px' }}
                  preview={false}
                  src="/images/employee-options.png"
                />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Card>

      <Card hoverable={false}>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 3 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="vertical-container">
              <Avatar
                className="custom-avatar"
                size="large"
                icon={<UserOutlined />}
                src={props.report.user.avatar_thumbnail_s_url}
              />
              <Link
                to={`/dashboard/ranking/${props.report.user.username}`}
                style={{
                  color: '#11c1ee',
                  fontSize: props.isMobile ? '16px' : '14px',
                  fontWeight: 'bold',
                }}
              >
                {props.report.user.username}
              </Link>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 16 }}
            md={{ span: 16 }}
            lg={{ span: 11 }}
          >
            <div className="flex-inline mb-3">
              <p className="report-label">{t('reports.form.attack-type')}:</p>
              <p className="report-detail">
                {props.report.attack_type
                  ? t(`reports.attack-list.${props.report.attack_type}`)
                  : t('reports.not-entered')}
              </p>
            </div>
            <div className="flex-inline mb-3">
              <p className="report-label">{t('reports.form.severity')}:</p>
              <p
                className="severity-container"
                style={{
                  border: `2px solid ${severityAttr.color}`,
                  color: severityAttr.color,
                  marginRight: '1vh',
                }}
              >
                {t(`reports.filters.severity.${props.report.severity}`)}
              </p>
              <p className="report-detail">({props.report.severity_score})</p>
            </div>
            {!props.companyMode && (
              <div className="flex-inline mb-3">
                <p className="report-label">{t('reports.status.label')}:</p>
                <p className="report-detail">
                  {props.report.is_draft
                    ? t('reports.status.draft')
                    : t(`reports.status.${props.report.status}`)}
                </p>
              </div>
            )}
            <div className="flex-inline mb-3">
              <p className="report-label">Target:</p>
              <Spin spinning={props.programLoading || !props.program}>
                <p className="report-detail">
                  {props.report.targets.length > 0 ? (
                    props.report.targets.map((targetId, index) => {
                      const target =
                        props.program &&
                        props.program.targets &&
                        props.program.targets.find(
                          (item) => item.id === targetId
                        );

                      if (target) {
                        return <div key={index}>{target.target}</div>;
                      }
                    })
                  ) : (
                    <span>{t('other')}</span>
                  )}
                </p>
              </Spin>
            </div>
            <div className="flex-inline-start">
              <p className="report-label">
                {t('reports.form.error-location')}:
              </p>
              {props.report.urls.length ? (
                props.report.urls.map((url, index) => (
                  <p className="report-detail-list report-detail" key={index}>
                    {url.url}
                  </p>
                ))
              ) : (
                <span>No url</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 16, offset: 3 }}
            md={{ span: 16, offset: 3 }}
            lg={{ span: 9, offset: 0 }}
          >
            <div>
              <div className="flex-inline mb-3">
                <p className="report-label">{t('reports.form.program')}:</p>
                {props.report.program.name && (
                  <Link
                    to={`/dashboard/programs/${props.report.program.id}`}
                    className="link-text"
                  >
                    {props.report.program.name}
                    {props.report.program.is_closed &&
                      t('programs.status.closed')}
                  </Link>
                )}
              </div>
              <div className="flex-inline mb-3">
                <p className="report-label">{t('reports.form.friendly-id')}:</p>
                <p className="report-detail">{props.report.friendly_id}</p>
              </div>
              <div className="flex-inline mb-3">
                <p className="report-label">{t('reports.form.date')}:</p>
                <p className="report-detail">{addedAt}</p>
              </div>
              {props.canChangeMitigationStatus && (
                <div className="flex-inline mb-3">
                  <p className="report-label">
                    {t('reports.mitigation.status')}:
                  </p>
                  <p className="report-detail">
                    {renderMitigationStatus(mitigationStatus)}
                    {props.report.status === 'valid' && (
                      <>
                        <Dropdown
                          trigger={['click']}
                          menu={{
                            items: mitigationMenuItems,
                            onClick: (e) => {
                              let status = e.key;
                              if (status === 'pending') {
                                status = null;
                              }
                              props.handleChangeMitigationStatus(status);
                            },
                          }}
                        >
                          <EditOutlined
                            style={{ color: 'black', marginLeft: '5px' }}
                            onMouseEnter={(e) =>
                              (e.target.style.color = 'blue')
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.color = 'black')
                            }
                          />
                        </Dropdown>
                        {latestCompletedRetest &&
                          latestCompletedRetest.mitigation_status !==
                            mitigationStatus &&
                          mitigationStatus !== 'pending' && (
                            <Tooltip title={t('reports.retest.warning')}>
                              <WarningOutlined className="ml-3" />
                            </Tooltip>
                          )}
                      </>
                    )}
                  </p>
                </div>
              )}
              {props.canChangeRetestStatus && (
                <>
                  <div className="flex-inline mb-1">
                    <p className="report-label">Retest: </p>
                    <p className="report-detail">
                      {['pending', 'ongoing'].includes(
                        props.report.retest_status
                      ) ? (
                        <>
                          {t(`reports.retest.${props.report.retest_status}`)}
                          <span
                            style={{
                              color: 'blue',
                              textDecoration: 'none',
                              cursor: 'pointer',
                              marginLeft: '10px',
                            }}
                            onClick={() => {
                              const nextStatus =
                                props.report.retest_status === 'pending'
                                  ? 'ongoing'
                                  : 'completed';
                              props.handleChangeRetestStatus(nextStatus);
                            }}
                            onMouseOver={(e) =>
                              (e.target.style.textDecoration = 'underline')
                            }
                            onMouseOut={(e) =>
                              (e.target.style.textDecoration = 'none')
                            }
                          >
                            {props.report.retest_status === 'pending'
                              ? t('reports.retest.start') + ' >'
                              : t('reports.retest.finish') + ' >'}
                          </span>
                        </>
                      ) : (
                        <span style={{ cursor: 'pointer' }}>
                          {props.report.status === 'valid' ? (
                            <span
                              style={{
                                color: 'blue',
                                textDecoration: 'none',
                                cursor: 'pointer',
                              }}
                              onMouseOver={(e) =>
                                (e.target.style.textDecoration = 'underline')
                              }
                              onMouseOut={(e) =>
                                (e.target.style.textDecoration = 'none')
                              }
                            >
                              <Popconfirm
                                title={t('reports.retest.confirm')}
                                onConfirm={handleRequestRetest}
                                okText="Sí"
                                cancelText="No"
                              >
                                {t('reports.retest.request')}
                              </Popconfirm>
                            </span>
                          ) : (
                            t('reports.retest.not-available')
                          )}
                          {props.report.status === 'valid' && (
                            <RightOutlined
                              style={{ color: 'blue', marginLeft: '5px' }}
                            />
                          )}
                        </span>
                      )}
                    </p>
                  </div>
                  {latestCompletedRetest && (
                    <>
                      <div className="flex-inline mb-1">
                        <HistoryOutlined />
                        <p className="report-label small ml-1">
                          {t('reports.retest.last-retest')}:{' '}
                          {latestCompletedRetest.finished_at
                            ? moment(latestCompletedRetest.finished_at).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            : '?'}
                          {' - '}
                          {latestCompletedRetest.mitigation_status
                            ? t(
                                `reports.mitigation.${latestCompletedRetest.mitigation_status}`
                              )
                            : '?'}
                        </p>
                      </div>
                      <div>
                        <p
                          className="link-text small ml-1"
                          onClick={showModal}
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          {t('reports.retest.see-details')}
                        </p>
                      </div>
                      <RetestModal
                        isOpen={isModalVisible}
                        onClose={closeModal}
                        retests={props.retests}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Card>

      <Card hoverable={false}>
        <h3 style={{ fontSize: '16px', marginBottom: '25px' }}>
          {t('reports.attack-detail')}
        </h3>

        <div className="mb-3">
          <p className="report-label">{t('reports.form.description')}</p>
          <div
            className="color-form-data report-detail"
            style={{ wordWrap: 'break-word' }}
          >
            <Marckdown
              source={props.report.description ? props.report.description : ''}
              options={MarkOptions}
              className="markdown-input"
            />
          </div>
        </div>
        <div className="mb-3">
          <p className="report-label">{t('reports.form.impact')}</p>
          <div
            className="color-form-data report-detail"
            style={{ wordWrap: 'break-word' }}
          >
            <Marckdown
              source={props.report.impact ? props.report.impact : ''}
              options={MarkOptions}
              className="markdown-input"
            />
          </div>
        </div>

        <div className="mb-3">
          <p className="report-label">{t('reports.form.counter')}</p>
          <div
            className="color-form-data report-detail"
            style={{ wordWrap: 'break-word' }}
          >
            <Marckdown
              source={
                props.report.additional_information
                  ? props.report.additional_information
                  : ''
              }
              options={MarkOptions}
              className="markdown-input"
            />
          </div>
        </div>

        <div className="mb-3">
          <p className="report-label">{t('reports.form.references.label')}</p>
          <div
            className="color-form-data report-detail"
            style={{ wordWrap: 'break-word' }}
          >
            <Marckdown
              source={props.report.references ? props.report.references : ''}
              options={MarkOptions}
              className="markdown-input"
            />
          </div>
        </div>

        {!props.report.program.is_ptaas && (
          <React.Fragment>
            <div className="mb-3">
              <p className="report-label">Request</p>
              <div
                className="color-form-data report-detail"
                style={{ wordWrap: 'break-word' }}
              >
                <Marckdown
                  source={props.report.request ? props.report.request : ''}
                  options={MarkOptions}
                  className="markdown-input"
                />
              </div>
            </div>

            <div className="mb-3">
              <p className="report-label">{t('reports.form.vulnerable')}</p>
              <div
                className="color-form-data report-detail"
                style={{ wordWrap: 'break-word' }}
              >
                <Marckdown
                  source={props.report.affected_parameters}
                  options={MarkOptions}
                  className="markdown-input"
                />
              </div>
            </div>

            <div className="mb-3">
              <p className="report-label">{t('reports.form.payload')}</p>
              <div
                className="color-form-data report-detail"
                style={{ wordWrap: 'break-word' }}
              >
                <Marckdown
                  source={props.report.payload}
                  options={MarkOptions}
                  className="markdown-input"
                />
              </div>
            </div>
          </React.Fragment>
        )}

        {props.report.proof_of_concept && (
          <div className="mb-3">
            <p className="report-label">{t('reports.form.poc')}</p>
            <div
              className="attribute-container color-form-data report-detail"
              style={{ wordWrap: 'break-word' }}
            >
              <Marckdown
                source={
                  props.report.proof_of_concept
                    ? props.report.proof_of_concept
                    : ''
                }
                options={MarkOptions}
                className="markdown-input"
              />
            </div>
          </div>
        )}
      </Card>
      {!_.isEmpty(props.report.steps) && (
        <Card hoverable={false}>
          <ReportStepsDetail steps={props.report.steps} />
        </Card>
      )}

      {!props.report.is_draft && (
        <CommentList
          canAdd={props.canAddComment}
          comments={props.comments}
          report={props.report}
          onAddComment={props.onAddComment}
          activeComment={props.activeComment}
        />
      )}
      <Card hoverable={false}>
        <h4 className="section-subtitle">
          <Image
            style={{ width: '17px', height: '17px' }}
            preview={false}
            src={`/images/history.png`}
          />
          {t('reports.history')}
        </h4>
        <br />
        {props.report ? (
          <div id="History">
            <HistoryList
              activities={props.activities}
              loading={props.activitiesLoading}
              totalItems={props.activitiesTotalItems}
              filters={props.activitiesFilters}
              totalPages={Math.ceil(props.activitiesTotalItems / itemsPerPage)}
              currentPage={props.activitiesFilters.page || 1}
              handlePageChange={props.handleActivitiesPageChange}
            />
          </div>
        ) : (
          <LoaderComponent />
        )}
      </Card>
    </div>
  );
};

const styleButtonDrop = {
  height: '40px',
  marginLeft: '15px',
  position: 'relative',
  bottom: '5px',
  border: 'none',
  padding: '10px',
};

export default withRouter(ReportDetail);
