import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Image, Row, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import withSizes from 'react-sizes';
import { createProgram } from '../redux/actions/program';
import { withTranslation } from 'react-i18next';
import { mapSizes } from '../mapSizes';

class UserDetail extends PureComponent {
  state = {
    loadingButton: false,
  };

  getNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  createProgramDraft = () => {
    const { dispatch } = this.props;

    this.setState({
      loadingButton: true,
    });
    dispatch(createProgram());
  };

  verifyUrl = (url) => {
    const res = url.match(
      /^(?:https?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/g
    );
    return res !== null;
  };

  getUserDetails = () => {
    const { currentUser, avatarLoaded, onRanking, isMobile, t } = this.props;
    return (
      <div id="hackerDetails">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 18, offset: 3 }}
        >
          <Row gutter={12}>
            <Col xs={{ span: 8 }} sm={4} md={4}>
              <div className="user-avatar-container">
                <Avatar
                  size="large"
                  src={
                    avatarLoaded || onRanking
                      ? currentUser.avatar_thumbnail_s_url
                      : ''
                  }
                />
              </div>
              <div className="social-icons">
                {currentUser.twitter_url ? (
                  <a href={`${currentUser.twitter_url}`} target="blank">
                    <Image
                      src={
                        process.env.PUBLIC_URL + '/images/twitter-dashboard.png'
                      }
                      preview={false}
                      width={30}
                    />
                  </a>
                ) : (
                  ''
                )}
                {currentUser.linkedin_url ? (
                  <a
                    href={
                      this.verifyUrl(currentUser.linkedin_url)
                        ? currentUser.linkedin_url
                        : '/not-found'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={
                        process.env.PUBLIC_URL +
                        '/images/linkedin-dashboard.png'
                      }
                      preview={false}
                      width={30}
                    />
                  </a>
                ) : (
                  ''
                )}
              </div>
            </Col>
            <Col
              xs={16}
              sm={12}
              md={{ span: 10, offset: 2 }}
              style={{ marginTop: !isMobile && '60px' }}
            >
              <h2>
                <b>{currentUser.username}</b>
              </h2>
              {onRanking ? (
                ''
              ) : (
                <p className="country">
                  {currentUser.country
                    ? currentUser.country.untranslated_name
                    : ''}
                </p>
              )}
              <p className="description">{currentUser.about}</p>
            </Col>

            {onRanking ? (
              <Col xs={12} sm={8} md={8}>
                {
                  currentUser.is_verified ? (
                    <Card className="identified">{t('ranking.verified')}</Card>
                  ) : (
                    ''
                  )
                  // <Card className='no-identified'>ID No Verificada</Card>
                }
              </Col>
            ) : (
              <span style={{ padding: 0 }}>
                <Col xs={8} sm={4} md={4}>
                  <h5 className="subTitle">Ranking</h5>
                  <p className="ranking">{currentUser.ranking}</p>
                </Col>
                <Col xs={8} sm={4} md={4}>
                  <h5 className="subTitle">Puntos</h5>
                  <p className="score">{currentUser.score}</p>
                </Col>
              </span>
            )}
          </Row>
        </Col>
      </div>
    );
  };

  render() {
    return this.getUserDetails();
  }
}

const mapStateToProps = (state, ownProps) => ({
  avatarLoaded: state.currentUser.avatarLoaded,
});
export default withTranslation()(
  withRouter(withSizes(mapSizes)(connect(mapStateToProps)(UserDetail)))
);
